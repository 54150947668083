import React from "react";
import "../../Styles/About.css";
import SecurityLogo from "../../Images/TalkFintech.webp";
import TeamMemberCard from "./TeamCard"; 

export const AboutPage = () => {
  const teamMembers = [
    {
      imgSrc: "https://ondot.com/wp-content/uploads/elementor/thumbs/Kanika-Goswami-Editor-in-Chief--pyke832f3d4q4aygptiz8la0mujwak6ijhxkzfoev8.gif",
      name: "Kanika Goswami",
      role: "Editor-in-Chief",
      description:
        "Senior journalist with more than two decades in the media, and a deep understanding of enterprise technology content.",
    },
    {
      imgSrc: "https://kgv.ae/wp-content/uploads/2023/03/Sameer-Datta-OnDot-jpg.webp",
      name: "Sameer Datta",
      role: "Publisher",
      description:
        "Worked for global brands for over 15 years, driving business development, operations, and global media sales and marketing teams.",
    },
    {
      imgSrc: "https://ondot.com/wp-content/uploads/elementor/thumbs/Kelly-Paice-VP-EMEA-pyke8409a760fwx3kbxlt31h88f9i9a8vml2gpn0p0.gif",
      name: "Kelly Paice",
      role: "VP EMEA",
      description:
        "Over fifteen years of expertise in eMedia as Head of Client Delivery and Ziff Davis, Kelly brings deep expertise in lead generation.",
    },
    {
      imgSrc: "https://ondot.com/wp-content/uploads/elementor/thumbs/Martin-Dela-Cruz%E2%80%8B-VP-APAC-pyke7zb2c0zktv3xbrwgym869b2ffrrl6zbn2btzk4.gif",
      name: "Martin Dela Cruz",
      role: "VP APAC",
      description:
        "Martin is an expert in building start-up businesses and makes them visible in the arena. He has worked as Operations Manager, Technical Support, Investor Relations Executive, Pastry Chef, Recruiter, Author Partner, and Director of Business Development.",
    },
    {
      imgSrc: "https://ondot.com/wp-content/uploads/elementor/thumbs/Eric-Yoshizuru-VP-AMER-pyke824kwj3fsoztvb4co3ik1goj2v2s7da3i5pt1g.gif",
      name: "Eric Yoshizuru",
      role: "VP Americas",
      description:
        "A Strategic Marketing Professional with 15+ years of digital, product and corporate marketing experience in the technology sector; Eric has proven ability to drive measurable top and bottom line results by building exceptional marketing teams and programs.",
    },
    {
      imgSrc: "https://ondot.com/wp-content/uploads/elementor/thumbs/Pranab-Ghosh-Director-Marketing-OnDOt-pyke808wiv0v5h2k6ab3j3zmuoxsngvbj3z4jlsldw.gif",
      name: "Pranab Ghosh",
      role: "Marketing Director",
      description:
        "Pranab has a track record of working on complex projects from scratch; scaling them up into multi-million dollar businesses. At Ondot, he heads the marketing team and strategizes for establishing the brand Ondot globally, as a leader in B2B technology media marketing.",
    },
  ];

  return (
    <>
      <div className="container container-max">
        <div className="row">
          <h1 className="fw-bold h2 py-1 mt-3 borderB">About Us</h1>
          <div className="col-md-8">
            <h2 className="fw-bold h4 mt-3">Acing the Science of Marketing Technologies</h2>
            <p className="mt-3">
              As technology becomes the Strategic Differentiator in every business function, learn about the global insights of marketing leadership- how to leverage innovative marketing technologies that are rapidly acquiring the digital edge.
            </p>
            <p className="mt-3">
              TalkFintech is a digital platform that creates conversations on Marketing Technology and Digital Transformation of the marketing function. Through active and interesting news, views and interviews with industry leaders, it will afford global exposure of the best Strategies and Innovations – paving a path for Smarter Marketing Decisions.
            </p>
          </div>
          <div className="col-md-4 AboutLogo">
            <img
              style={{ width: "70%" }}
              className="m-auto"
              src={SecurityLogo}
              alt="TalkFintech Logo"
            />
          </div>
        </div>
      </div>

      <div className="container container-max mt-5">
        <div className="row">
          <h3 className="fw-bold borderB py-1 h4">The Team</h3>
          <div className="col-md-12">
            <div className="row">
              {teamMembers.map((member, index) => (
                <TeamMemberCard
                  key={index}
                  imgSrc={member.imgSrc}
                  name={member.name}
                  role={member.role}
                  description={member.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


