import React from "react";

const TeamMemberCard = ({ imgSrc, name, role, description }) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="card p-3">
        <img
          src={imgSrc}
          style={{ borderRadius: "10px" }}
          alt={name}
          className="w-100"
        />
        <div className="p-md-3 colorRed card" style={{ fontSize: "14px", padding: "10px" }}>
          <div className="fw-bold border-bottom card-title h5">{name}</div>
          <p className="mt-1" style={{ fontSize: "15px" }}>
            {role}
          </p>
          <p className="cardText card-text">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberCard;
