import React from "react";

const Privacy = () => {
  return (
   
      <div className="container container-max">
        <div className="row">
          <div className="col-md-12">
            <div>
              <h1 className="fw-bold h5 mt-3">
                Privacy Policy for TalkFintech.com
              </h1>
              <p>
                The privacy of our visitors to{" "}
                <a href="https://talkfintech.com/" className="pRed">
                  www.talkfintech.com
                </a>{" "}
                is important to us.
              </p>{" "}
              <br />
              <p>
                At{" "}
                <a href="https://talkfintech.com/" className="pRed">
                  www.talkfintech.com
                </a>{" "}
                we recognize that privacy of your personal information is
                important. Here is information on what types of personal
                information we receive and collect when you use and visit{" "}
                <a href="https://talkfintech.com/" className="pRed">
                  www.talkfintech.com
                </a>
                , and how we safeguard your information. We never sell your
                personal information to third parties.
              </p>
            </div>

            <div>
              <h2 className="fw-bold h5 mt-4">Log Files</h2>
              <p>
                As with most other websites, we collect and use the data
                contained in log files. The information in the log files include
                your IP (internet protocol) address, your ISP (internet service
                provider, such as AOL or Shaw Cable), the browser you used to
                visit our site (such as Internet Explorer or Firefox), the time
                you visited our site and which pages you visited throughout our
                site.
              </p>
            </div>

            <div>
              <h3 className="fw-bold h5 mt-4">Cookies and Web Beacons</h3>
              <p>
                We do use cookies to store information, such as your personal
                preferences when you visit our site. This could include only
                showing you a popup once in your visit, or the ability to login
                to some of our features, such as forums.
              </p>{" "}
              <br />
              <p>
                We also use third party advertisements on{" "}
                <a href="https://talkfintech.com/" className="pRed">
                  www.talkfintech.com
                </a>{" "}
                to support our site. Some of these advertisers may use
                technology such as cookies and web beacons when they advertise
                on our site, which will also send these advertisers (such as
                Google through the Google AdSense program) information including
                your IP address, your ISP , the browser you used to visit our
                site, and in some cases, whether you have Flash installed. This
                is generally used for geotargeting purposes (showing New York
                real estate ads to someone in New York, for example) or showing
                certain ads based on specific sites visited (such as showing
                cooking ads to someone who frequents cooking sites).
              </p>
            </div>

            <div>
              <h4 className="fw-bold h5 mt-4">DoubleClick DART cookies</h4>
              <p>
                We also may use DART cookies for ad serving through Google’s
                DoubleClick, which places a cookie on your computer when you are
                browsing the web and visit a site using DoubleClick advertising
                (including some Google AdSense advertisements). This cookie is
                used to serve ads specific to you and your interests (”interest
                based targeting”).
              </p>{" "}
              <br />
              <p>
                The ads served will be targeted based on your previous browsing
                history (For example, if you have been viewing sites about
                visiting Las Vegas, you may see Las Vegas hotel advertisements
                when viewing a non-related site, such as on a site about
                hockey). DART uses “non personally identifiable information”. It
                does NOT track personal information about you, such as your
                name, email address, physical address, telephone number, social
                security numbers, bank account numbers or credit card numbers.
              </p>{" "}
              <br />
              <p>
                You can opt-out of this ad serving on all sites using this
                advertising by visiting
                http://www.doubleclick.com/privacy/dart_adserving.aspx You can
                choose to disable or selectively turn off our cookies or
                third-party cookies in your browser settings, or by managing
                preferences in programs such as Norton Internet Security.
                However, this can affect how you are able to interact with our
                site as well as other websites. This could include the inability
                to login to services or programs, such as logging into forums or
                accounts.
              </p>{" "}
              <br />
              <p>
                Deleting cookies does not mean you are permanently opted out of
                any advertising program. Unless you have settings that disallow
                cookies, the next time you visit a site running the
                advertisements, a new cookie will be added.
              </p>{" "}
              <br />
            </div>

            <div>
              <h4 className="fw-bold h5 mt-4">Our Contact Information</h4>
              <p>
                This privacy policy has been compiled to better serve those who
                are concerned with how their ‘Personally identifiable
                information’ (PII) is being used online. PII, as used in US
                privacy law and information security, is information that can be
                used on its own or with other information to identify, contact,
                or locate a single person, or to identify an individual in
                context. Please read our privacy policy carefully to get a clear
                understanding of how we collect, use, protect or otherwise
                handle your Personally Identifiable Information in accordance
                with our website.
              </p>
            </div>

            <section>
              <h4 className="fw-bold h5 mt-4">
                What personal information do we collect from the people that
                visit our blog, website or app?
              </h4>
              <p>
                When ordering or registering on our site, as appropriate, you
                may be asked to enter your name, email address, mailing address
                or other details to help you with your experience.
              </p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">When do we collect information?</h5>
              <p>
                We collect information from you when you subscribe to a
                newsletter or enter information on our site.
              </p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">How do we use your information?</h5>
              <p>
                We may use the information we collect from you when you
                register, make a purchase, sign up for our newsletter, respond
                to a survey or marketing communication, surf the website, or use
                certain other site features in the following ways:
              </p>{" "}
              <br />
              <ul>
                <li>To improve our website in order to better serve you.</li>{" "}
                <br />
                <li>
                  To allow us to better service you in responding to your
                  customer service requests.
                </li>{" "}
                <br />
                <li>
                  To send periodic emails regarding your order or other products
                  and services.
                </li>{" "}
                <br />
              </ul>
            </section>

            <section>
              <h5 className="fw-bold mt-3">
                How do we protect visitor information?
              </h5>

              <ul>
                <li>
                  We do not use vulnerability scanning and/or scanning to PCI
                  standards.
                </li>{" "}
                <br />
                <li>
                  We only provide articles and information. We never ask for
                  credit card numbers.
                </li>{" "}
                <br />
                <li>We use regular Malware Scanning.</li> <br />
                <li>We do not use an SSL certificate</li> <br />
                <li>
                  We only provide articles and information. We never ask for
                  personal or private information like names, email addresses,
                  or credit card numbers.
                </li>{" "}
                <br />
              </ul>
            </section>

            <section>
              <h5 className="fw-bold mt-3">Do we use ‘cookies’?</h5>
              <p>We do not use cookies for tracking purposes.</p> <br />
              <p>
                You can choose to have your computer warn you each time a cookie
                is being sent, or you can choose to turn off all cookies. You do
                this through your browser (like Internet Explorer) settings.
                Each browser is a little different, so look at your browser’s
                Help menu to learn the correct way to modify your cookies.
              </p>{" "}
              <br />
              <p>
                If you disable cookies off, some features will be disabled that
                make your site experience more efficient and some of our
                services will not function properly.
              </p>{" "}
              <br />
              <p>However, you can still place orders.</p> <br />
            </section>

            <section>
              <h5 className="fw-bold mt-3">Third-party disclosure</h5>
              <p>
                We do not sell, trade, or otherwise transfer to outside parties
                your personally identifiable information. Please fill out the
                form{" "}
                <a href="/opt-out-form" className="pRed">
                  Do Not Sell My Information
                </a>{" "}
                and get your confirmation.{" "}
              </p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">Third-party links</h5>
              <p>
                Occasionally, at our discretion, we may include or offer
                third-party products or services on our website. These
                third-party sites have separate and independent privacy
                policies. We therefore have no responsibility or liability for
                the content and activities of these linked sites. Nonetheless,
                we seek to protect the integrity of our site and welcome any
                feedback about these sites.
              </p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">Google</h5>
              <p>
                Google’s advertising requirements can be summed up by Google’s
                Advertising Principles. They are put in place to provide a
                positive experience for users.
                https://support.google.com/adwordspolicy/answer/1316548?hl=en
              </p>{" "}
              <br />
              <p>We use Google AdSense Advertising on our website.</p> <br />
              <p>
                Google, as a third-party vendor, uses cookies to serve ads on
                our site. Google’s use of the DART cookie enables it to serve
                ads to our users based on previous visits to our site and other
                sites on the Internet. Users may opt-out of the use of the DART
                cookie by visiting the Google Ad and Content Network privacy
                policy.
              </p>
            </section>

            <div>
              <h5 className="fw-bold mt-3">
                We have implemented the following:
              </h5>

              <ul>
                <li>Remarketing with Google AdSense</li> <br />
                <li>Google Display Network Impression Reporting</li>
              </ul>

              <p>
                We along with third-party vendors, such as Google use
                first-party cookies (such as the Google Analytics cookies) and
                third-party cookies (such as the DoubleClick cookie) or other
                third-party identifiers together to compile data regarding user
                interactions with ad impressions and other ad service functions
                as they relate to our website.
              </p>
            </div>

            <div>
              <h5 className="fw-bold mt-3">Opting out:</h5>
              <p>
                Users can set preferences for how Google advertises to you using
                the Google Ad Settings page. Alternatively, you can opt out by
                visiting the Network Advertising initiative opt out page or
                permanently using the Google Analytics Opt Out Browser add on.
              </p>
            </div>

            <div>
              <h5 className="fw-bold mt-3">
                California Online Privacy Protection Act
              </h5>
              <p>
                CalOPPA is the first state law in the nation to require
                commercial websites and online services to post a privacy
                policy. The law’s reach stretches well beyond California to
                require a person or company in the United States (and
                conceivably the world) that operates websites collecting
                personally identifiable information from California consumers to
                post a conspicuous privacy policy on its website stating exactly
                the information being collected and those individuals with whom
                it is being shared, and to comply with this policy. – See more
                at:
                http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
              </p>
            </div>

            <div>
              <h5 className="fw-bold mt-3">
                According to CalOPPA we agree to the following:
              </h5>
              <ul>
                <li>Users can visit our site anonymously.</li> <br />
                <li>
                  Once this privacy policy is created, we will add a link to it
                  on our home page or as a minimum on the first significant page
                  after entering our website.
                </li>{" "}
                <br />
                <li>
                  Our Privacy Policy link includes the word ‘Privacy’ and can be
                  easily be found on the page specified above.
                </li>
              </ul>
              <p>Users will be notified of any privacy policy changes:</p>{" "}
              <br />
              <ul>
                <li>On our Privacy Policy Page</li>
              </ul>
              <p>Users are able to change their personal information:</p> <br />
              <ul>
                <li>By emailing us</li>
              </ul>
              <p>Users are able to opt-out from the website:</p> <br />
              <ul>
                <li>
                  <a href="/opt-out-form" className="pRed fw-bold mt-3">
                    Do Not Sell My Information
                  </a>
                </li>
              </ul>
              <p>Users can contact us via CCPA helpline:</p> <br />
              <ul>
                <li>
                  <a
                    className="text-black fw-bold mt-3 pRed"
                    href="tel:+18005462816"
                  >
                    1800-546-2816
                  </a>{" "}
                </li>
              </ul>
            </div>

            <section>
              <h5 className="fw0-bold">
                How does our site handle do not track signals?
              </h5>
              <p>
                We honor do not track signals and do not track, plant cookies,
                or use advertising when a Do Not Track (DNT) browser mechanism
                is in place.
              </p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">
                Does our site allow third-party behavioral tracking?
              </h5>
              <p>
                It’s also important to note that we allow third-party behavioral
                tracking
              </p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">
                COPPA (Children Online Privacy Protection Act)
              </h5>
              <p>
                When it comes to the collection of personal information from
                children under 13, the Children’s Online Privacy Protection Act
                (COPPA) puts parents in control. The Federal Trade Commission,
                the nation’s consumer protection agency, enforces the COPPA
                Rule, which spells out what operators of websites and online
                services must do to protect children’s privacy and safety
                online.
              </p>{" "}
              <br />
              <p>We do not specifically market to children under 13</p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">Fair Information Practices</h5>
              <p>
                The Fair Information Practices Principles form the backbone of
                privacy law in the United States and the concepts they include
                have played a significant role in the development of data
                protection laws around the globe. Understanding the Fair
                Information Practice Principles and how they should be
                implemented is critical to comply with the various privacy laws
                that protect personal information.
              </p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">
                In order to be in line with Fair Information Practices we will
                take the following responsive action, should a data breach
                occur:
              </h5>
              <p>We will notify the users via email</p>
              <br />
              <ul>
                <li>Within 1 business day</li>
              </ul>
              <p>
                We also agree to the Individual Redress Principle, which
                requires that individuals have a right to pursue legally
                enforceable rights against data collectors and processors who
                fail to adhere to the law. This principle requires not only that
                individuals have enforceable rights against data users, but also
                that individuals have recourse to courts or government agencies
                to investigate and/or prosecute non-compliance by data
                processors.
              </p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">CAN SPAM Act</h5>
              <p>
                The CAN-SPAM Act is a law that sets the rules for commercial
                email, establishes requirements for commercial messages, gives
                recipients the right to have emails stopped from being sent to
                them, and spells out tough penalties for violations.
              </p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">
                We collect your email address in order to:
              </h5>
              <h5 className="fw-bold mt-3">
                To be in accordance with CANSPAM we agree to the following:
              </h5>
              <p>
                If at any time you would like to unsubscribe from receiving
                future emails, you can email us at and we will promptly remove
                you from <span className="fw-bold mt-3">ALL</span>{" "}
                correspondence.
              </p>
            </section>

            <section>
              <h5 className="fw-bold mt-3">Feedback and Query</h5>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our use of your personal data, please contact us our Data
                Protection Officer at{" "}
                <a
                  className="text-black fw-bold pRed"
                  href="mailto: dpo@talkfintech.com"
                >
                  dpo@talkfintech.com
                </a>
              </p>
            </section>
            <br />
          </div>
        </div>
      </div>
   
  );
};

export default Privacy;
