import React from "react";
import "../Styles/About.css";
import {AboutPage} from "./AboutComp/AboutPage"
// import SecurityLogo from "../Images/TalkFintech.webp";
import TeamMemberCard from "./AboutComp/TeamCard";


export const About = () => {
  return (
    <>
   <AboutPage />
   <TeamMemberCard/>
    </>
  );
};
